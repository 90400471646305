import React from 'react';
import {Image} from "./Image";

type AvatarProps = {
    src: string;
    className?: string;
    alt?: string;
    size?: string;
    title?: string;
    lazy?: boolean;
}

export const Avatar = ({className, src, alt='Avatar Image', size, title, lazy=false}: AvatarProps) => {
    return (
        <Image src={src} className={`avatar ${className ? className : ''} avatar-${size ? size : 'sm'}`} alt={alt}
               title={title} lazy={lazy}/>
    );
};